// client/src/Footer.js

import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { copyTextToClipboard } from '../utils/Utils.js';

function Footer() {
  
  const [isCopied, setIsCopied] = useState(false);
  const githubLink = 'https://github.com/nbungu';
  const linkedInLink = 'https://www.linkedin.com/in/arne-schunter';
  const copyText = 'bauphysiktool@gmail.com';

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className="px-5 py-2 z-3">
     
      <ul className="nav justify-content-center border-bottom border-secondary px-0 py-3">
        <li className="nav-item">
          <Link to="/impressum" className="nav-link text-body-secondary">Impressum / Datenschutzerklärung</Link>
        </li>
      </ul>

      <div className="row align-items-center m-0">
        <div className='col-sm-3'></div>
        <div className="col-md-6 px-0 py-3 text-center">
          <p className='text-body-secondary'>© 2025 Baupyhsik Tool</p>
        </div>
        <div className="col-md-3 text-center">
          <a href={linkedInLink ? linkedInLink : '#'} className="btn btn-secondary rounded-circle ms-2 p-2 lh-1" title="LinkedIn"><i className="bi bi-linkedin"/></a> 
          <a href={githubLink ? githubLink : '#'} className="btn btn-secondary rounded-circle ms-2 p-2 lh-1" title="GitHub"><i className="bi bi-github"/></a> 
          <button type="button" onClick={handleCopyClick} className="btn btn-secondary rounded-circle ms-2 p-2 lh-1" title="Email-Addresse kopieren"><i className={isCopied ? "bi bi-clipboard-check" : "bi bi-envelope-at"}/></button>
        </div>
      </div>

    </div>
  )
}

export default Footer