// client/src/pages/BlogPost.js

import React, { useEffect } from "react";
import { STRAPI_CMS_URL } from '../utils/Utils.js';
import { usePost } from '../utils/fetchContent';
import { useParams } from "react-router-dom";
import LoadingSpinner from '../components/LoadingSpinner.js';
import RichTextBlocks from "../components/RichTextBlocks.js";

import Header from '../components/Header.js';
import Footer from '../components/Footer.js';

import defaultAvatar from '../assets/blog_avatar.png';

function BlogPost() {

  // Dynamic Routing
  const params = useParams(); //params.id => dynamic value defined as id in route ('/posts/1234' -> params.id equals 1234)
  const id = params.id;
  const post = usePost(id);

  useEffect(() => {
    document.title = "News & Updates > BauphysikTool";
  }, []);

  return (
    <main>
        <Header/>
        <section class="blog-details-area pt-120 pb-120">
            {!post ? <LoadingSpinner/> :
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xl-10">
                        <div class="blog-details-wrap">
                            <div class="bd-content-top text-center">
                                <div class="blog-meta-two">
                                    <ul class="list-wrap">
                                        <li class="tag"><a href="/" style={{cursor: "none"}}>{post.category}</a></li>
                                        <li><i class="bi bi-clock"></i>{post.readTime}</li>
                                    </ul>
                                </div>
                                <h2 class="title">{post.title}</h2>
                                <p>{post.caption}</p>
                                <div class="blog-meta-two bottom">
                                    <ul class="list-wrap">
                                        <li class="avatar"><img src={defaultAvatar} alt=''/>{post.author}</li>
                                        <li><i class="bi bi-calendar-week"></i>{post.date}</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="blog-details-img">{!post.titleImage ? <img alt=''/> : <img src={STRAPI_CMS_URL + post.titleImage.url} alt=""/>}</div>
                            <div class="bd-content-bottom">
                                {post.content && <RichTextBlocks richtext={post.content}/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>}                  
        </section>
        <Footer/>
    </main>
  )
}

export default BlogPost