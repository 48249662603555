// client/src/components/ServicesArea.js
import React from 'react';
import { STRAPI_CMS_URL } from '../utils/Utils.js';
import { useServices } from '../utils/fetchContent';
import LoadingSpinner from './LoadingSpinner.js';

function ServicesArea() {
    
    const services = useServices();

    if (!services) {
        return <LoadingSpinner message={"Lade Funktionen..."} />;
    }

    return (
        <section className="inner-services-area pb-100">
            <div className="container">
                <div className="inner-services-item-wrap">
                    <div className="row justify-content-center">
                        {services.map((service, index) => (
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8" key={index}>
                            <div className="services-item-four">
                                <div className="services-icon-four">
                                {!service.icon ? <div/> : <img src={STRAPI_CMS_URL + service.icon.url} alt=""/>}
                                </div>
                                <div className="services-content-four">
                                    <h2 className="title"><a href="/services-details" style={{cursor: 'none' }}>{service.title}</a></h2>
                                    <p>{service.caption}</p>
                                </div>
                            </div>
                        </div>))}
                    </div>
                </div>
            </div>
        </section>        
)}

export default ServicesArea
