import { useEffect, useState } from 'react';
import { STRAPI_CMS_URL } from './Utils';

// added queryString as a dependency in the useEffect dependency array to ensure that the effect is re-run whenever the queryString changes
export const useBannerArea = () => {
    const [bannerArea, setBannerArea] = useState(null);
    const queryString = STRAPI_CMS_URL + "/api/banner-area?populate[download][populate][0]=file&populate[image][fields][1]=url&populate[featureImage1][fields][1]=url&populate[featureImage2][fields][1]=url&populate[featureImage3][fields][1]=url";
    
    useEffect(() => {
        const fetchContent = async () => {
            const response = await fetch(queryString);
            const result = await response.json();
            setBannerArea(result.data);
        };
        fetchContent();
    }, [queryString]);

    return bannerArea;
};

// added queryString as a dependency in the useEffect dependency array to ensure that the effect is re-run whenever the queryString changes
export const useAboutArea = () => {
    const [aboutArea, setAboutArea] = useState(null);
    const queryString = STRAPI_CMS_URL + "/api/about-area?populate=*";
    
    useEffect(() => {
        const fetchContent = async () => {
            const response = await fetch(queryString);
            const result = await response.json();
            setAboutArea(result.data);
        };
        fetchContent();
    }, [queryString]);

    return aboutArea;

};

// added queryString as a dependency in the useEffect dependency array to ensure that the effect is re-run whenever the queryString changes
export const useServices = (paginationLimit, sortOrder) => {
    if (!paginationLimit) paginationLimit = 8;
    if (!sortOrder) sortOrder = "desc";
    const [services, setServices] = useState(null);
    const queryString = STRAPI_CMS_URL + "/api/services-areas?sort=publishedAt:" + sortOrder + "&populate=*&pagination[start]=0&pagination[limit]=" + paginationLimit;
    
    useEffect(() => {
        const fetchContent = async () => {
            const response = await fetch(queryString);
            const result = await response.json();
            setServices(result.data);
        };
        fetchContent();
    }, [queryString]);

    return services;
};

// added id as a dependency in the useEffect dependency array to ensure that the effect is re-run whenever the id changes
export const usePost = (id) => {
    const [post, setPost] = useState(null);
    
    // Use filters to fetch by "id" instead of "documentId"
    const queryString = `${STRAPI_CMS_URL}/api/posts?filters[id][$eq]=${id}&populate=*`;

    useEffect(() => {
        const fetchContent = async () => {
            try {
                const response = await fetch(queryString);
                const result = await response.json();

                if (result.data.length > 0) {
                    setPost(result.data[0]); // Access first matching post
                    document.title = result.data[0].title;
                } else {
                    setPost(null);
                }
            } catch (error) {
                console.error("Error fetching post:", error);
            }
        };

        fetchContent();
    }, [id, queryString]);

    return post;
};

// added paginationLimit and sortOrder as a dependency in the useEffect dependency array to ensure that the effect is re-run whenever those change
// export const usePosts = (paginationLimit, sortOrder) => {
//     if (!paginationLimit) paginationLimit = 3;
//     if (!sortOrder) sortOrder = "desc";

//     const [posts, setPosts] = useState(null);
//     const queryString = STRAPI_CMS_URL + "/api/posts?sort=publishedAt:" + sortOrder + "&populate=*&pagination[start]=0&pagination[limit]=" + paginationLimit;
    
//     useEffect(() => {
//         const fetchContent = async () => {
//             const response = await fetch(queryString);
//             const result = await response.json();
//             setPosts(result.data);
//         };
//         fetchContent();
//     }, [paginationLimit, sortOrder, queryString]);
//     const reversedPosts = posts ? [...posts].reverse() : null;
    
//     return { posts, reversedPosts };
// };

export const usePosts = (paginationLimit = 3, sortOrder = "desc", category = "") => {
    const [posts, setPosts] = useState(null);
    
    // Construct the query string
    let queryString = `${STRAPI_CMS_URL}/api/posts?sort=publishedAt:${sortOrder}&populate=*&pagination[start]=0&pagination[limit]=${paginationLimit}`;

    // Apply category filter if it's not "ALL"
    if (category !== "") {
        queryString += `&filters[category][$eq]=${category}`;
    }

    useEffect(() => {
        const fetchContent = async () => {
            try {
                const response = await fetch(queryString);
                const result = await response.json();
                setPosts(result.data);
            } catch (error) {
                console.error("Error fetching posts:", error);
            }
        };
        fetchContent();
    }, [paginationLimit, sortOrder, category]); // Re-fetch when category changes

    const reversedPosts = posts ? [...posts].reverse() : null;

    return { posts, reversedPosts };
};

export const useDownloads = (paginationLimit, sortOrder) => {
    if (!paginationLimit) paginationLimit = 5;
    if (!sortOrder) sortOrder = "desc";

    const [downloads, setDownloads] = useState(null);
    const queryString = STRAPI_CMS_URL + "/api/downloads?sort=publishedAt:" + sortOrder + "&populate=*&pagination[start]=0&pagination[limit]=" + paginationLimit;
    
    useEffect(() => {
        const fetchContent = async () => {
            const response = await fetch(queryString);
            const result = await response.json();
            setDownloads(result.data);
        };
        fetchContent();
    }, [paginationLimit, sortOrder, queryString]);
    const reversedDownloads = downloads ? [...downloads].reverse() : null;
    
    return { downloads, reversedDownloads };
};