// client/src/components/BannerArea.js

import React, { useState } from 'react';
import { STRAPI_CMS_URL } from '../utils/Utils.js';
import { useBannerArea } from "../utils/fetchContent.js";
import LoadingSpinner from './LoadingSpinner.js';

function BannerArea() {
  const pageContent = useBannerArea();
  const [hoveredPoint, setHoveredPoint] = useState(null);

  const hoverPoints = pageContent ? [
    { id: 1, top: '23%', left: '75%', image: STRAPI_CMS_URL + pageContent.featureImage1.url },
    { id: 2, top: '20%', left: '30%', image: STRAPI_CMS_URL + pageContent.featureImage2.url },
    { id: 3, top: '55%', left: '55%', image: STRAPI_CMS_URL + pageContent.featureImage3.url }
  ] : [];

  if (!pageContent) {
    return <LoadingSpinner message={"Lade Banner..."} />;  // Wait for the content to load
  }

  return (
    <section className="banner-bg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="banner-img">
                {pageContent.image.url ? (
                  <img src={STRAPI_CMS_URL + pageContent.image.url} alt="" className="main-image" />
                ) : (
                  <div className="no-image">No image available</div>
                )}
                {hoverPoints.map(point => (
                  <div
                    key={point.id}
                    className="hover-point"
                    style={{ top: point.top, left: point.left }}
                    onMouseEnter={() => { setHoveredPoint(point.id) }}
                    onMouseLeave={() => { setHoveredPoint(null) }}>
                    {hoveredPoint === point.id && (
                      <img src={point.image} alt={`Feature ${point.id}`} className="popup-image" />
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="banner-content">
                <span className="sub-title">Kostenloses <strong>Baupyhsik</strong> Tool</span>
                <h1 className="title">{pageContent.title}</h1>
                <a className="btn" href={STRAPI_CMS_URL + pageContent.download?.file.url} download={pageContent.download?.fileName} style={{ cursor: "none" }}><i className="fs-5 bi bi-download pe-3" />Download<span></span></a>
                <p className='py-2'>Version {pageContent.download?.semanticVersion} | {pageContent.download?.platform}</p>
              </div>
            </div>
          </div>
        </div>
      
    </section>
  );
}

export default BannerArea;